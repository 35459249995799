<h1 mat-dialog-title>Escolha o número de telefone</h1>
<mat-dialog-content>
  <mat-form-field class="w-100">
    <mat-select cdkFocusInitial #phoneSelect>
      @for (phone of phones; track $index) {
        <mat-option [value]="phone">
          {{ phone | phone }}
        </mat-option>
      }
    </mat-select>

  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button
          [mat-dialog-close]="phoneSelect.value"
          [disabled]="!phoneSelect.value"
          color="primary">Confirmar</button>
</mat-dialog-actions>
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutService } from 'src/app/service/layout.service';

@Component({
  selector: 'app-choose-phone-to-message',
  templateUrl: './choose-phone-to-message.component.html',
  styleUrl: './choose-phone-to-message.component.scss'
})
export class ChoosePhoneToMessageComponent implements OnInit, OnDestroy {

  phones: [string, string];
  
  constructor (
    @Inject(MAT_DIALOG_DATA) private data: [string, string],
    private _dialogRef: MatDialogRef<ChoosePhoneToMessageComponent>,
    private _layout: LayoutService,
  ) {
    this.phones = data;
    console.log({data, phones: this.phones})
  }
  
  ngOnInit(): void {
    this._layout.loader = false;
  }

  ngOnDestroy(): void {
    this._layout.loader = true;
  }
}
